// Here you can add other styles

.main-logo {
  position: relative;
  top: 4px;
}

.footer-logo {
  position: relative;
  top: 4px;
}

.mobile-logo {
  position: relative;
  top: 15px;
}

.mobile-title {
  position: relative;
  top: -13px;
}

.welcome-user {
  position: relative;
  top: 8px;
}
