pre {
  background-color: #f2f3f4;
  white-space: pre-wrap;
}

.docs-main {
  display: grid;
  place-items: center;
}

.docs-container {
  width: 50vw;
}

.docs-header {
  text-align: center;
  padding: 1rem 0;
}

.docs-section {
  padding-bottom: 3rem;

  &__header {
    text-align: center;
    padding-bottom: 1rem;
  }

  &__heading {
    background-color: #d3d3d3;
    font-size: 24px;
  }

  &__item {
    margin: 0 3em;
    padding: 0.5rem;

    &--desc {
      margin-bottom: 0;
      margin-left: 3em;
    }
  }

  &--inner_section {
    margin-bottom: 2rem;
  }
}

@media (max-width: 1024px) {
  .docs-container {
    width: 95vw;
  }
}
