@import '@/styles/vars';

.hap_prize-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 2rem;
}

.hap_icon {
  all: unset;
  color: $HAP-primary;
  font-size: 16px;

  &:hover {
    transition: 0.2s;
    color: $HAP-text-highlight;
  }
}

.hap_link {
  all: unset;
  color: #000;
  display: block;

  &:hover {
    transition: 0.2s;
    color: $HAP-primary;
  }
}

.hap_close {
  border: 0;
  font-size: 16px;

  &:hover {
    color: $HAP-primary;
    background-color: transparent;
  }
}

.hap_form > div {
  margin-bottom: 10px;
}

.hap_date-picker {
  width: 30vw;
}
